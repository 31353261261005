import * as QueueActionTypes from '../config/queue_action_types';

// types of action
const Types = {
    LIST_HEALTH_RISKS_REQUEST: 'LIST_HEALTH_RISKS_REQUEST',
    LIST_HEALTH_RISKS_SUCCESS: 'LIST_HEALTH_RISKS_SUCCESS',
    GET_HEALTH_RISK_REQUEST: 'GET_HEALTH_RISK_REQUEST',
    GET_HEALTH_RISK_SUCCESS: 'GET_HEALTH_RISK_SUCCESS',
    CREATE_HEALTH_RISK_REQUEST: 'CREATE_HEALTH_RISK_REQUEST',
    CREATE_HEALTH_RISK_SUCCESS: 'CREATE_HEALTH_RISK_SUCCESS',
    CREATE_HEALTH_RISK_FAILURE: 'CREATE_HEALTH_RISK_FAILURE',
    UPDATE_HEALTH_RISK_REQUEST: 'UPDATE_HEALTH_RISK_REQUEST',
    UPDATE_HEALTH_RISK_SUCCESS: 'UPDATE_HEALTH_RISK_SUCCESS',
    UPDATE_HEALTH_RISK_FAILURE: 'UPDATE_HEALTH_RISK_FAILURE',
    DELETE_HEALTH_RISK_REQUEST: 'DELETE_HEALTH_RISK_REQUEST',
    DELETE_HEALTH_RISK_SUCCESS: 'DELETE_HEALTH_RISK_SUCCESS',
    DELETE_HEALTH_RISK_FAILURE: 'DELETE_HEALTH_RISK_FAILURE',
    REMOVE_CREATION_ERROR: 'REMOVE_CREATION_ERROR',
    REMOVE_UPDATE_ERROR: 'REMOVE_UPDATE_ERROR',
    REMOVE_CONFLICT: 'REMOVE_CONFLICT',
    SAVE_HEALTH_RISK_LOCALLY: 'SAVE_HEALTH_RISK_LOCALLY',
    UPDATE_HEALTH_RISK_STATUS_REQUEST: 'UPDATE_HEALTH_RISK_STATUS_REQUEST',
    CLEAR_VISIT_HEALTH_RISKS: 'CLEAR_VISIT_HEALTH_RISKS',
    REMOVE_HEALTH_RISK: 'REMOVE_HEALTH_RISK',
};

// actions
const getHealthRisks = (farmId, risk_type, melaAgreement) => ({
    type: Types.LIST_HEALTH_RISKS_REQUEST,
    meta: {
        risk_type,
        offline: {
            effect: { url: Routes.health_risk_list_path({ farm_id: farmId, risk_type: risk_type }) },
            commit: { type: Types.LIST_HEALTH_RISKS_SUCCESS, meta: { farmId: farmId, tableKey: StringHelper.random(8), melaAgreement: melaAgreement, risk_type: risk_type } },
            queue: { method: QueueActionTypes.READ, key: farmId, scope: 'health_risk_list' }
        }
    }
});

const getHealthRisk = (id, isTempId, hasError) => ({
    type: Types.GET_HEALTH_RISK_REQUEST,
    meta: initActionMeta({
        id: id,
        hasError: hasError,
        offline: {
            effect: { url: Routes.health_risk_get_path(id) },
            commit: { type: Types.GET_HEALTH_RISK_SUCCESS, meta: { tableKey: StringHelper.random(8) } },
            queue: { method: QueueActionTypes.READ, key: id, scope: 'health_risk' }
        }
    }, isTempId || hasError)
});

const createHealthRisk = (token, risk_type, params, strId, visitId, farmId, isLocal) => ({
    type: Types.CREATE_HEALTH_RISK_REQUEST,
    meta: initActionMeta({
        risk_type,
        params: { ...params },
        strId: strId,
        updatedAt: new Date().toISOString(),
        visitId: visitId,
        farmId: farmId,
        offline: {
            effect: { url: Routes.health_risk_create_path({ visit_id: visitId, risk_type: risk_type }), method: 'POST', body: JSON.stringify({ authenticity_token: token, health_risk: formatParams(params) }) },
            commit: { type: Types.CREATE_HEALTH_RISK_SUCCESS, meta: { strId: strId, tableKey: StringHelper.random(8) } },
            rollback: { type: Types.CREATE_HEALTH_RISK_FAILURE, meta: { strId: strId, tableKey: StringHelper.random(8) } },
            queue: { method: QueueActionTypes.CREATE, key: strId, scope: 'health_risk' }
        }
    }, isLocal)
});

const updateHealthRisk = (id, risk_type, token, params, strId, isTempId, isLocal, savedAt) => ({
    type: Types.UPDATE_HEALTH_RISK_REQUEST,
    meta: initActionMeta({
        id: id,
        risk_type,
        params: { ...params },
        strId: strId,
        tableKey: StringHelper.random(8),
        updatedAt: savedAt,
        offline: {
            effect: { url: Routes.health_risk_update_path({ health_risk_id: id, saved_at: savedAt, risk_type: risk_type }), method: 'POST', body: JSON.stringify({ authenticity_token: token, health_risk: formatParams(params) }) },
            commit: { type: Types.UPDATE_HEALTH_RISK_SUCCESS, meta: { id: id, strId: strId, tableKey: StringHelper.random(8) } },
            rollback: { type: Types.UPDATE_HEALTH_RISK_FAILURE, meta: { id: id, strId: strId, tableKey: StringHelper.random(8) } },
            queue: { method: QueueActionTypes.UPDATE, key: id, isTempId: isTempId, scope: 'health_risk' }
        }
    }, isLocal)
})

const deleteHealthRisk = (id, risk_type ,token, strId, isTempId) => ({
    type: Types.DELETE_HEALTH_RISK_REQUEST,
    meta: initActionMeta({
        id: id,
        strId: strId,
        tableKey: StringHelper.random(8),
        risk_type: risk_type,
        offline: {
            effect: { url: Routes.health_risk_delete_path({ health_risk_id: id }), method: 'POST', body: JSON.stringify({ authenticity_token: token }) },
            commit: { type: Types.DELETE_HEALTH_RISK_SUCCESS, meta: { id: id, strId: strId } },
            rollback: { type: Types.DELETE_HEALTH_RISK_FAILURE, meta: { id: id, strId: strId, tableKey: StringHelper.random(8), risk_type: risk_type } },
            queue: { method: QueueActionTypes.DELETE, key: id, isTempId: isTempId, scope: 'health_risk' }
        }
    }, isTempId)
})

const removeCreationError = (strId) => ({
    type: Types.REMOVE_CREATION_ERROR,
    meta: { strId: strId }
});

const removeUpdateError = (id) => ({
    type: Types.REMOVE_UPDATE_ERROR,
    meta: { id: id }
});

const removeConflict = (id) => ({
    type: Types.REMOVE_CONFLICT,
    meta: { id: id }
});

const saveHealthRiskLocally = (json) => ({
    type: Types.SAVE_HEALTH_RISK_LOCALLY,
    meta: json
});

const updateStatus = (token, id, status, desc) => ({
    type: Types.UPDATE_HEALTH_RISK_STATUS_REQUEST,
    meta: {
        id: id,
        status: status,
        desc: desc,
        tableKey: StringHelper.random(8),
        offline: {
            effect: { url: Routes.health_risk_update_status_path({ health_risk_id: id }), method: 'POST', body: JSON.stringify({ authenticity_token: token, status: status, description: desc }) },
            queue: { method: QueueActionTypes.UPDATE, key: id, scope: 'health_risk_status_write' }
        }
    }
});

const clearVisitHealthRisks = (id) => ({
    type: Types.CLEAR_VISIT_HEALTH_RISKS,
    meta : {
        id: id
    }
});

const removeHealthRisk = (id) => ({
    type: Types.REMOVE_HEALTH_RISK,
    meta : {
        id: id
    }
});

// Removes offline attribute from actions that should remain local
function initActionMeta(meta, isLocal) {
    if (isLocal) delete meta.offline;
    return meta;
}

// Modify params to similar format than when submitting the form normally
function formatParams(params) {
    params.files = { ...params.fileParams };
    delete params.fileParams;

    return params;
}

export default {
    Types,
    getHealthRisks,
    getHealthRisk,
    createHealthRisk,
    updateHealthRisk,
    deleteHealthRisk,
    removeCreationError,
    removeUpdateError,
    removeConflict,
    saveHealthRiskLocally,
    updateStatus,
    clearVisitHealthRisks,
    removeHealthRisk
};