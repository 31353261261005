import { createStore, applyMiddleware, compose } from "redux";
import reducer from "./reducers/root";

import { offline } from '@redux-offline/redux-offline';
import offlineConfig from './config/index';

// Devtools and logger can be uncommented if you want use them in development
// Do not commit usage of them
// import logger from "redux-logger";
// import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState) {
    const store = createStore(
        reducer,
        initialState,
        //composeWithDevTools(
        compose(
            // applyMiddleware(logger),
            offline(offlineConfig)
        )
    );
    return store;
}