import React from 'react';
import Loading from '../components/loading';

export class Base extends React.Component {
    static get BODY_ID() {
        return '';
    }

    static get requireUser() {
        return true;
    }

    static get requireTranslations() {
        return true;
    }

    constructor(props) {
        super(props);

        this.state = {
            translationsLoaded: !this.constructor.requireTranslations ? true : false,
            userLoaded: !this.constructor.requireUser ? true : false,
            bodyId: !this.constructor.requireUser ? this.constructor.BODY_ID : 'loading',
            cookiePreferencesModalOpen: false,
        };

        this.signOut = this.signOut.bind(this);
    }

    render() {
        if (this.state.translationsLoaded && this.state.userLoaded && !this.state.loading) {
            return this.renderLayout();
        } else {
            return <Loading />;
        }
    }

    componentDidMount() {
        this.setBodyId();
        this.setDocumentLang();

        if (!this.state.translationsLoaded) {
            this.loadTranslations();
        }

        if (!this.state.userLoaded) {
            this.loadUser();
        }

        jQuery(document).ajaxError(this.handleAjaxErrors);
    }

    componentDidUpdate() {
        this.setBodyId();
        this.setDocumentLang();
    }

    setBodyId() {
        document.body.setAttribute('id', this.state.bodyId || 'loading');
    }

    setDocumentLang() {
        document.documentElement.setAttribute("lang",I18n.locale);
    }

    loadUser() {
        jQuery.getJSON(Routes.login_user_path(), (result) => {
            User.current = result;
            this.setState({
                userLoaded: true,
                bodyId: this.constructor.BODY_ID
            });
        }).fail(() => {
            this.signOut();
        });
    }

    loadTranslations() {
        jQuery.getJSON(Routes.translations_path(), (result) => {
            I18n.keys = result.keys;
            I18n.locale = result.locale;
            I18n.available_locales = result.available_locales;
            I18n.default_locale = result.default_locale;
            this.setState({
                translationsLoaded: true,
            });
        });
    }

    signOut(event, doneCallback) {
        this.setState({
            loading: true,
            bodyId: 'loading'
        });
        jQuery.getJSON(Routes.logout_path(), (result) => {
            if (typeof doneCallback == 'function') {
                doneCallback();
            }
            sessionStorage.clear();
            // Fresh start by doing a full page load
            window.location = result.path;
        });
        if (event) {
            event.preventDefault();
        }
    }

    // Generic ajax error handler for "403 Access denied" errors
    handleAjaxErrors = (event, jqxhr, settings, thrownError) => {
        if (jqxhr.status === 403) {
            let response = null;
            try {
                response = JSON.parse(jqxhr.responseText);
            } catch (e) {
                // No-op
            }

            // In case of require_login
            if (response && response.login_required) {
                window.location = '/';
            }
        }
    }

    toggleCookiePreferences = () => {
      this.setState({cookiePreferencesModalOpen: !this.state.cookiePreferencesModalOpen})
    }
};


window.User = {
    current: {},

    hasPermission(permissionCode) {
        if (this.current.permissions) {
            return this.current.permissions.indexOf(permissionCode) != -1;
        } else {
            return false;
        }
    },

    mayPerformVisit(visit) {
        return User.hasPermission('view_admin_visits') && (
            !visit.is_delegated ||
            (
                User.hasPermission('perform_all_visits') ||
                (
                    visit.delegation_organization_id == User.current.organization_id &&
                    ['assignation_accepted'].indexOf(visit.delegation_status) != -1
                )
            )
        );
    },

    get messaging_enabled() {
        return User.current.messaging_enabled;
    }
}


window.I18n = {
    keys: {},
    locale: null,
    available_locales: [],
    default_locale: null,
    
    t: function (k, params) {
        let p = params || {};
        let translation = undefined;
        let keys = this.keys[p.locale || this.locale] || {};

        if (p.scope) {
            translation = (keys[p.scope][k] || '').slice(0); // clone
        } else if (typeof keys[k] == 'object') {
            return keys[k];
        } else {
            translation = (keys[k] || '').slice(0); // clone
        }

        if (translation.length == 0) {
            return k;
        }

        for (let paramKey in p) {
            if (paramKey != 'scope') {
                translation = translation.replace(new RegExp('%{' + paramKey + '}', 'ig'), p[paramKey]);
            }
        }
        return translation;
    }
}

//window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } };