import * as QueueActionTypes from '../config/queue_action_types';

// types of action
const Types = {
    LIST_MESSAGES_REQUEST: 'LIST_MESSAGES_REQUEST',
    LIST_MESSAGES_SUCCESS: 'LIST_MESSAGES_SUCCESS',
    READ_MESSAGE_REQUEST: 'READ_MESSAGE_REQUEST',
    READ_MESSAGE_SUCCESS: 'READ_MESSAGE_SUCCESS'
};

// actions
const listMessages = (category) => ({
    type: Types.LIST_MESSAGES_REQUEST,
        meta: {
        offline: {
            effect: { url: Routes.message_list_path({ category: category }) },
            commit: { type: Types.LIST_MESSAGES_SUCCESS, meta: { category: category } },
            queue: { method: QueueActionTypes.READ, key: 'list', scope: 'message_read' }
        }
    }
});

const readMessage = (token, receivedMessageId) => ({
    type: Types.READ_MESSAGE_REQUEST,
    meta: {
        offline: {
            effect: { url: Routes.message_read_path({ received_message_id: receivedMessageId }), method: 'POST', body: JSON.stringify({ authenticity_token: token }) },
            commit: { type: Types.READ_MESSAGE_SUCCESS, meta: { receivedMessageId: receivedMessageId } },
            queue: { method: QueueActionTypes.UPDATE, key: receivedMessageId, scope: 'message_write' }
        }
    }
});

export default {
    Types,
    listMessages,
    readMessage
};