import ACTIONS from "../actions/announcement";
import _ from "lodash";

const defaultState = {
    announcements: [],
    loading: true,
    unreadCount: 0,
    listKey: StringHelper.random(8),
};

const announcementReducer = (state = defaultState, action) => {
    switch (action.type) {
        // LIST ANNOUNCEMENTS
        case ACTIONS.Types.LIST_ANNOUNCEMENTS_SUCCESS: {
            let newState = _.cloneDeep(state);
            const { rows, unread_count } = action.payload;
            newState.announcements = rows;
            newState.loading = false;
            newState.unreadCount = unread_count;

            return newState;
        }

        // READ ANNOUNCEMENT
        case ACTIONS.Types.READ_ANNOUNCEMENT_SUCCESS: {
            let newState = _.cloneDeep(state);
            
            // Refresh announcement list
            newState.listKey = StringHelper.random(8);

            return newState;
        }

        // DISMISS ANNOUNCEMENT
        case ACTIONS.Types.DISMISS_ANNOUNCEMENT_SUCCESS: {
            let newState = _.cloneDeep(state);
            
            // Refresh announcement list
            newState.listKey = StringHelper.random(8);

            return newState;
        }
        default:
            return state;
    }
};
export default announcementReducer;