
// types of action
const Types = {
    SAVE_ANSWER_DATA: 'SAVE_ANSWER_DATA',
    SAVE_HIDDEN_STATUS_DATA: 'SAVE_HIDDEN_STATUS_DATA',
    MARK_FORM_DIRTY: 'MARK_FORM_DIRTY',
    MARK_FORM_CLEAN: 'MARK_FORM_CLEAN',
};

// actions
const saveAnswerData = (key, data) => ({
    type: Types.SAVE_ANSWER_DATA,
    meta: {
        key: key,
        data: data,
    }
});

const saveHiddenStatusesData = (key, data) => ({
    type: Types.SAVE_HIDDEN_STATUS_DATA,
    meta: {
        key: key,
        data: data,
    }
});

const markFormDirty = () => ({
    type: Types.MARK_FORM_DIRTY,
});

const markFormClean = () => ({
    type: Types.MARK_FORM_CLEAN,
});

export default {
    Types,
    saveAnswerData,
    saveHiddenStatusesData,
    markFormDirty,
    markFormClean,
};