import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue';
import { enqueue } from './enqueue';
import ACTIONS from '../actions/health_risk';

export default {
    ...defaultQueue,
    // enqueue is responsible for adding request to the queue.
    enqueue,
    // dequeue is resposible for removing request from the queue after it has been finished.
    dequeue: function(array, item, context) {
        let rest = [...array];

        // Find first item which doesn't contain temporary string ids of health risks
        // These can appear when health risk is created and visit report is saved in offline mode.
        const idx = rest.findIndex(
            ({
                meta: {
                    params = {},
                    melaAgreement = false
                }
            }) => !params.health_risk_ids || params.health_risk_ids.findIndex(id => isNaN(id)) === -1 || !melaAgreement
        );
        if (idx !== -1) {
            rest.splice(idx, 1);
        }

        if (item.type === ACTIONS.Types.CREATE_HEALTH_RISK_SUCCESS) {
            const { strId } = item.meta;
            const request = rest.find(
                ({
                    meta: { params = {} }
                }) => params.health_risk_ids && params.health_risk_ids.indexOf(strId) !== -1
            );

            // Update health risk strId to id, so the request can be processed from the queue.
            if (request) {
                const { id } = item.payload;
                const i = request.meta.params.health_risk_ids.indexOf(strId);
                
                // These are used for checking the ids.
                request.meta.params.health_risk_ids[i] = id;
                let body = JSON.parse(request.meta.offline.effect.body);

                // This contains the actual request health risk ids.
                body.visit_report.health_risk_ids.push(id);
                request.meta.offline.effect.body = JSON.stringify(body);
            }
        }
        return rest;
    },
    // peek is responsible for getting request from the queue to be sent to the server.
    peek: function(array, item, context) {
        // Find first item which doesn't contain temporary string ids of health risks
        // These can appear when health risk is created and visit report is saved in offline mode.
        return array.filter(
            ({
                meta: { 
                    params = {},
                    melaAgreement = false
                }
            }) => !params.health_risk_ids || params.health_risk_ids.findIndex(id => isNaN(id)) === -1 || !melaAgreement
        )[0];
    }
};