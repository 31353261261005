/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../layouts/loading';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import configureStore from '../redux/store';
const reduxStore = configureStore();

// Layout loader
const Layout = (layout) => {
  return Loadable({
    loader: () => import('../layouts/' + layout),
    loading: Loading,
    delay: 300
  });
};

// Switch layout based on route
class Application extends React.Component {
  render() {
    return <Router>
      <Provider store={reduxStore}>
        <CookiesProvider defaultSetOptions={{ path: '/', maxAge: 60*60*24*365 }}>
          <Switch>
            <Redirect exact path="/tth" to="/tth/login" />
            <Route path="/tth/login" component={Layout('login')} />
            <Route path="/tth" component={Layout('admin')} />
            <Route path="/customer" component={Layout('customer')} />
            <Route path="/error" component={Layout('error')} />
            <Route path="/" component={Layout('login')} />
          </Switch>
        </CookiesProvider>
      </Provider>
    </Router>;
  }
}

// Insert application to DOM
document.addEventListener("DOMContentLoaded", () => {
  document.body.innerHTML = '<div id="app"></div>';
  const container = document.getElementById('app');
  const root = createRoot(container);
  root.render(React.createElement(Application, {}));
});