import { combineReducers } from 'redux'
import healthRisk from './health_risk'
import visit from './visit'
import message from './message';
import visitReport from './visit_report'
import announcement from './announcement'
import form from './form'

const appReducer = combineReducers({
    healthRisk,
    visit,
    message,
    visitReport,
    announcement,
    form,
});

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_DATA') {
        state = undefined;
    }

    return appReducer(state, action);
}

export default rootReducer;