// Modified from https://github.com/redux-offline/tools/blob/master/packages/smart-queue/enqueue.js
import { CREATE, UPDATE, DELETE, READ } from './queue_action_types';

function validate(key) {
  if (key === null) {
    throw new Error('Missing key, every queue action should have a key!');
  }
}

function indexOfAction(array, key, scope) {
  return array.findIndex(
    ({
      meta: {
        offline: { queue = null }
      }
    }) => queue && queue.key == key && queue.scope === scope && !queue.disableMerge
  );
}

function mergeActions(queueAction, newAction) {
  return {
    ...queueAction,
    meta: {
      ...queueAction.meta,
      offline: {
        ...newAction.meta.offline
      }
    }
  };
}

// This does not merge actions, but merges the body of the update to the create action.
function mergeUpdateToCreate(createAction, updateAction) {
  return {
    ...createAction,
    meta: {
      ...createAction.meta,
      offline: {
        ...createAction.meta.offline,
        effect: {
          ...createAction.meta.offline.effect,
          body: updateAction.meta.offline.effect.body
        }
      }
    }
  };
}

function safeToProceed(index, context) {
  return !context.offline.busy || index !== 0;
}

export function enqueue(array, action, context) {
  const outbox = array;
  let queueAction;
  const {
    meta: {
      offline: { queue = null }
    }
  } = action;

  if (!queue) {
    return [...outbox, action];
  }


  const { method = 'UNKNOWN', key = null, scope = 'app', isTempId = false } = queue;

  validate(key);
  const index = indexOfAction(outbox, key, scope);

  switch (method) {
    case CREATE:
      // TODO: Don't queue if no permission from farmer. Or check this in action creator
      if (index !== -1) {
        // eslint-disable-next-line no-console
        console.warn(
          'Duplicate CREATE action found, every CREATE action should have unique key!'
        );
        return outbox;
      }
      return [...outbox, action];
    case DELETE:
      if (index !== -1) {
        queueAction = outbox[index];
        if (
          safeToProceed(index, context) &&
          (queueAction.meta.offline.queue.method === UPDATE ||
            queueAction.meta.offline.queue.method === CREATE)
        ) {
          outbox.splice(index, 1);
        }
      }
      if (isTempId) {
        // Do not perform delete for temporary string IDs
        return outbox;
      }
      return [...outbox, action];
    case UPDATE:
      if (index !== -1) {
        queueAction = outbox[index];
        if (safeToProceed(index, context)) {
          if (queueAction.meta.offline.queue.method === CREATE) {
            outbox[index] = mergeUpdateToCreate(outbox[index], action);
            return outbox;
          } else if (queueAction.meta.offline.queue.method === UPDATE) {
            outbox[index] = mergeActions(outbox[index], action);
            return outbox;
          } else if (queueAction.meta.offline.queue.method === READ) {
            // UPDATE request takes priority over READ request to ensure READ doesn't return old data.
            // READ request is not removed because UPDATE might fail or not return the new data.
            let newOutbox = [...outbox];
            const readAction = newOutbox.splice(index, 1)[0];
            return [...newOutbox, action, readAction];
          }
        }
      }
      if (isTempId) {
        // Do not perform updates for temporary string IDs
        return outbox;
      }
      return [...outbox, action];
    case READ:
      if (index !== -1) {
        queueAction = outbox[index];
        if (
          safeToProceed(index, context) &&
          queueAction.meta.offline.queue.method === READ
        ) {
          outbox[index] = mergeActions(outbox[index], action);
        }
      } else {
        return [...outbox, action];
      }
      return outbox;
    default:
      throw new Error(
        'Missing method definition, the "method" value should be either of [CREATE, READ, DELETE, UPDATE]!'
      );
  }
}