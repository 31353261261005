import ACTIONS from "../actions/message";
import _ from "lodash";

const defaultState = {
    messages: {
        inbox: [],
        outbox: []
    }
};

const messageReducer = (state = defaultState, action) => {
    switch (action.type) {
        // LIST MESSAGES
        case ACTIONS.Types.LIST_MESSAGES_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.loading = true;

            return newState;
        }
        case ACTIONS.Types.LIST_MESSAGES_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.messages[action.meta.category] = action.payload;
            newState.loading = false;

            return newState;
        }

        // READ MESSAGE
        case ACTIONS.Types.READ_MESSAGE_SUCCESS: {
            let newState = _.cloneDeep(state);
            
            newState.messages.inbox.forEach((m, i) => {
                if (m.received_message_id == action.meta.receivedMessageId) {
                    newState.messages.inbox[i].read = true;
                    return;
                }
            });

            return newState;
        }
        default:
            return state;
    }
};
export default messageReducer;