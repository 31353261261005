import ACTIONS from "../actions/form";
import _ from "lodash";

const defaultState = {
    answers: {},
    hiddenStatuses: {},
    hasUnsavedChanges: false,
};

const formReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACTIONS.Types.SAVE_ANSWER_DATA: {
            let newState = _.cloneDeep(state);

            let data = action.meta.data;

            newState.answers[action.meta.key] = data;

            return newState;
        }
        case ACTIONS.Types.SAVE_HIDDEN_STATUS_DATA: {
            let newState = _.cloneDeep(state);

            let data = action.meta.data;

            newState.hiddenStatuses[action.meta.key] = data;

            return newState;
        }
        case ACTIONS.Types.MARK_FORM_DIRTY: {
            // Only update the state if hasUnsavedChanges changes
            if (!state.hasUnsavedChanges) {
                let newState = _.cloneDeep(state);
                newState.hasUnsavedChanges = true;
                return newState;
            }
            return state;
        }
        case ACTIONS.Types.MARK_FORM_CLEAN: {
            // Only update the state if hasUnsavedChanges changes
            if (state.hasUnsavedChanges) {
                let newState = _.cloneDeep(state);
                newState.hasUnsavedChanges = false;
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};
export default formReducer;