import ACTIONS from "../actions/health_risk";
import _ from "lodash";

const defaultState = {
    firstAidRisks: {},
    healthRisks: {},
    tableKey: null, // For forcing update on Table component
    updateHistory: {},
    deleteHistory: {},
    creationErrors: {},
    updateErrors: {},
    conflicts: []
};

const healthRiskReducer = (state = defaultState, action) => {
    const field = (action?.meta?.risk_type || action?.payload?.risk_type) === 'first_aid' ? 'firstAidRisks' : 'healthRisks';
    switch (action.type) {
        // LIST
        case ACTIONS.Types.LIST_HEALTH_RISKS_SUCCESS: {
            const newState = _.cloneDeep(state);

            if (action.meta.melaAgreement) {
                Object.keys(newState[field]).forEach(k => {
                    if (newState[field][k].farm_id == action.meta.farmId) {
                        delete newState[field][k];
                    }
                });
            }
            action.payload.forEach(r => {
                if ((action.meta.melaAgreement || !newState[field][r.id])) {
                    newState[field][r.id] = r;
                }
            });

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        // GET
        case ACTIONS.Types.GET_HEALTH_RISK_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState[field][action.payload.id] = action.payload;

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        // CREATE
        case ACTIONS.Types.CREATE_HEALTH_RISK_REQUEST: {
            const newState = _.cloneDeep(state);

            const params = action.meta.params;
            params.id = action.meta.strId;
            params.updated_by = User.current.id;
            params.updated_at = action.meta.updatedAt;
            params.created_at = action.meta.updatedAt;
            params.str_id = action.meta.strId;
            params.visit_id = action.meta.visitId;
            params.farm_id = action.meta.farmId;
            params.risk_type = action.meta.risk_type;

            // Creating health risks with multiple locations should be sent to redux one by one.
            params.location_id = params.location_ids[0];
            delete params.location_ids;

            newState[field][action.meta.strId] = params;

            delete newState.creationErrors[action.meta.strId];
            newState.tableKey = action.meta.strId;

            return newState;
        }

        case ACTIONS.Types.CREATE_HEALTH_RISK_SUCCESS: {
            const newState = _.cloneDeep(state);
            action.payload.forEach(health_risk =>{
              delete newState[field][action.meta.strId]
              newState[field][health_risk.id] = health_risk;
            })

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        case ACTIONS.Types.CREATE_HEALTH_RISK_FAILURE: {
            const newState = _.cloneDeep(state);

            const risk = newState[field][action.meta.strId];
            delete risk.id;
            risk.errors = action.payload.response.errors;
            newState.creationErrors[risk.str_id] = risk;

            delete newState[field][action.meta.strId];

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        // UPDATE
        case ACTIONS.Types.UPDATE_HEALTH_RISK_REQUEST: {
            const newState = _.cloneDeep(state);

            let risk = newState[field][action.meta.id];
            newState.updateHistory[risk.id] = newState.updateHistory[risk.id] || {};
            newState.updateHistory[risk.id][action.meta.strId] = risk;

            risk = Object.assign(risk, action.meta.params);
            risk.updated_at = action.meta.updatedAt;
            risk.updated_by = User.current.id;

            newState.conflicts = newState.conflicts.filter(c => c.id != action.meta.id);
            delete newState.updateErrors[action.meta.id];

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        case ACTIONS.Types.UPDATE_HEALTH_RISK_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState[field][action.payload.id] = action.payload;

            delete newState.updateHistory[action.meta.id][action.meta.strId];

            if (Object.keys(newState.updateHistory[action.meta.id]).length == 0) {
                delete newState.updateHistory[action.meta.id];
            }

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        case ACTIONS.Types.UPDATE_HEALTH_RISK_FAILURE: {
            const newState = _.cloneDeep(state);

            let risk = newState[field][action.meta.id];

            if (action.payload.response.errors) {
                // Add to update errors
                const r = _.cloneDeep(risk);
                r.errors = action.payload.response.errors;
                newState.updateErrors[r.id] = r;
            } else if (action.payload.status == 409) {
                newState.conflicts.push(risk);
            } else {
                const r = _.cloneDeep(risk);
                r.errors = { server: [{ message: I18n.t('server_error_message') }] };
                newState.updateErrors[r.id] = r;
            }

            if (newState.updateHistory[risk.id][action.meta.strId]) {
                const previous = newState.updateHistory[risk.id][action.meta.strId];
                risk = Object.assign(risk, previous);
            }

            delete newState.updateHistory[action.meta.id][action.meta.strId];

            if (Object.keys(newState.updateHistory[action.meta.id]).length == 0) {
                delete newState.updateHistory[action.meta.id];
            }

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        // DELETE
        case ACTIONS.Types.DELETE_HEALTH_RISK_REQUEST: {
            const newState = _.cloneDeep(state);

            const risk = newState[field][action.meta.id];

            newState.deleteHistory[risk.id] = newState.deleteHistory[risk.id] || {};
            newState.deleteHistory[risk.id][action.meta.strId] = risk;

            delete newState[field][action.meta.id];
            delete newState.updateErrors[action.meta.id];

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        case ACTIONS.Types.DELETE_HEALTH_RISK_SUCCESS: {
            const newState = _.cloneDeep(state);
            delete newState.deleteHistory[action.meta.id][action.meta.strId];

            if (Object.keys(newState.deleteHistory[action.meta.id]).length == 0) {
                delete newState.deleteHistory[action.meta.id];
            }

            return newState;
        }

        case ACTIONS.Types.DELETE_HEALTH_RISK_FAILURE: {
            const newState = _.cloneDeep(state);

            // TODO: If Delete replaced an update request, there is mismatch in server and browser data
            if (newState.deleteHistory[action.meta.id][action.meta.strId]) {
                const risk = newState.deleteHistory[action.meta.id][action.meta.strId];

                newState[field][action.meta.id] = risk;
            }

            delete newState.deleteHistory[action.meta.id][action.meta.strId];

            if (Object.keys(newState.deleteHistory[action.meta.id]).length == 0) {
                delete newState.deleteHistory[action.meta.id];
            }

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        // UPDATE STATUS
        case ACTIONS.Types.UPDATE_HEALTH_RISK_STATUS_REQUEST: {
            const newState = _.cloneDeep(state);
            delete newState[field][action.meta.id];
            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        // Other actions, which don't have SUCCESS / FAILURE actions
        case ACTIONS.Types.REMOVE_CREATION_ERROR: {
            const newState = _.cloneDeep(state);
            delete newState.creationErrors[action.meta.strId];

            return newState;
        }

        case ACTIONS.Types.REMOVE_UPDATE_ERROR: {
            const newState = _.cloneDeep(state);
            delete newState.updateErrors[action.meta.id];

            return newState;
        }

        case ACTIONS.Types.REMOVE_CONFLICT: {
            const newState = _.cloneDeep(state);
            newState.conflicts = newState.conflicts.filter(c => c.id != action.meta.id);

            return newState;
        }

        case ACTIONS.Types.SAVE_HEALTH_RISK_LOCALLY: {
            const newState = _.cloneDeep(state);
            newState[field][action.meta.id] = action.meta;
            newState.conflicts = newState.conflicts.filter(c => c.id != action.meta.id);
            delete newState.updateErrors[action.meta.id];
            // For forcing update on Table component
            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        case ACTIONS.Types.CLEAR_VISIT_HEALTH_RISKS: {
            const newState = _.cloneDeep(state);

            ['firstAidRisks','healthRisks', 'updateHistory', 'deleteHistory', 'creationErrors', 'updateErrors'].forEach(attr => {
                Object.keys(newState[attr]).forEach(k => {
                    if (newState[attr][k].visit_id == action.meta.id) {
                        delete newState[attr][k];
                    }
                });
            });

            newState.conflicts = newState.conflicts.filter(c => c.visit_id != action.meta.id);

            return newState;
        }

        case ACTIONS.Types.REMOVE_HEALTH_RISK: {
            const newState = _.cloneDeep(state);

            delete newState[field][action.meta.id];

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        default:
            return state;
    }
};
export default healthRiskReducer;